// Rivals Primary
$abyss: #040404;
$abyss-10: #d1d1d1;
$abyss-30: #9e9e9e;
$abyss-50: #858585;
$abyss-70: #525252;
$abyss-90: $abyss;

$bone: #fcfcfc;
$bone-10: $bone;
$bone-30: #e3e3e3;
$bone-50: #c9c9c9;
$bone-70: #b0b0b0;
$bone-90: #969696;

$eye-black: #1d1d1d;
$eye-black-10: #e8e8e8;
$eye-black-30: #9c9c9c;
$eye-black-50: #696969;
$eye-black-70: #363636;
$eye-black-90: $eye-black;

$sand: #f7f6f3;
$sand-10: $sand;
$sand-30: #e3e0d6;
$sand-50: #cdc8b6;
$sand-70: #b9b198;
$sand-90: #a49a7a;

// Rivals Secondary
$clay: #6d7061;
$clay-10: #d1d2cb;
$clay-30: #a0a394;
$clay-50: $clay;
$clay-70: #525549;
$clay-90: #383932;

$mud: #383631;
$mud-10: #bab7b0;
$mud-30: #8a867a;
$mud-50: #706c62;
$mud-70: #54514a;
$mud-90: $mud;

$stone: #908877;
$stone-10: #d6d3cc;
$stone-30: #bfbab0;
$stone-50: $stone;
$stone-70: #5a5549;
$stone-90: #3e3a32;

// Rivals Tertiary
$crimson: #e00d00;
$crimson-10: #ffe2e0;
$crimson-30: #ff5247;
$crimson-50: $crimson;
$crimson-70: #ad0a00;
$crimson-90: #7a0700;

$hoops: #ff5c00;
$hoops-10: #ffdecc;
$hoops-30: #ff9d66;
$hoops-50: $hoops;
$hoops-70: #cc4a00;
$hoops-90: #993700;

$star: #faff00;
$star-10: #ffffeb;
$star-30: #fdffb2;
$star-50: $star;
$star-70: #ccc900;
$star-90: #998c00;

$tertiary-gold: #ffd029;

$field: #5a7310;
$field-10: #e4f0c7;
$field-30: #b6cf6e;
$field-50: $field;
$field-70: #465a0c;
$field-90: #38480a;

$hydro: #4a3cef;
$hydro-10: #dee6fc;
$hydro-30: #8493f5;
$hydro-50: $hydro;
$hydro-70: #2413e7;
$hydro-90: #1c0fb8;

// Rivals Interactions
$recruit: #f8c701;
$recruit-10: #fff4c7;
$recruit-30: #fedf62;
$recruit-50: $recruit;
$recruit-70: #c69f01;
$recruit-90: #937601;

$transfer: #5287de;
$transfer-10: #d4e1f7;
$transfer-30: #94b5eb;
$transfer-50: $transfer;
$transfer-70: #296ad6;
$transfer-90: #2155ab;

$pass: #269a2b;
$pass-10: #e1efe1;
$pass-30: #92c494;
$pass-50: $pass;
$pass-70: #1c7320;
$pass-90: #124a15;

$fail: #ff0000;
$fail-10: #ffe5e5;
$fail-30: #ff9999;
$fail-50: $fail;
$fail-70: #cc0000;
$fail-90: #990000;

// Legacy Grays
$white: #fff;
$grayLightShade: #fafafa;
$gray95: #f2f2f2;
$gray90: #e6e6e6;
$gray85: #d9d9d9;
$gray80: #e8e9ea;
$gray60: #999999;
$gray50: #808080;
$gray30: #4d4d4d;
$black: #000000;

// Legacy Rivals Colors
$blue95: #e1eaf9;
$rivals-blue: #0a4ea3;
$rivals-gray: #f3f4f5;
$rivals-primary: #1f4f9d;
$rivals-secondary: #464646;
$card-gradient-light: #3e5262;
$card-gradient-dark: #1d2933;
$rivals-dark-link-blue: #34b2ff;
$commitment-blue: #88a7d0;
$primary-gold: #f79f31;

// Common
$active-blue: #2289e5;
$athens-gray: #f2f3f5;
$barley-white: #fff4cf;
$boulder: #777777;
$brown-tumbleweed: #3e3110;
$call-to-action-blue: #0d74cf;
$career-highlight-gray: #cecece;
$cta-blue-opacity-10: rgba($call-to-action-blue, 0.1);
$corn: #eebf02;
$curious-blue: #29a5c2;
$dark-orange: #ec9d08;
$dark-sky-blue: #2c96f3;
$divider-lines: rgba($gray85, 0.25);
$dodger-blue: #3399ff;
$ebony-clay: #262d39;
$fruit-salad: #4caf50;
$futurecast-orange: #f9a01b;
$green: #06cb02;
$light-green: #68c20c;
$lima: #6bc027;
$mine-shaft: #333333;
$offer-red: #6e0000;
$overlay-color: rgba($black, 0.4);
$persian-red: #d32f2f;
$portal-entry-yellow: #ffcf13;
$rivals-red: #cb0000;
$river-bed: #4a545c;
$rolling-stone: #777f85;
$rusty-nail: #845b00;
$scroll-indicator: rgba($black, 0.15);
$shark: #24272a;
$silver-sand: #bbbec1;
$sushi: #78b542;
$tangaroa: #041e34;
$transparent: transparent;
$transparent-white: rgba($white, 0.2);
$trout: #4c545b;
$white-opacity-50: rgba($white, 0.5);

// Ant Design (used to match Ant Designs default styles)
$link-hover: #40a9ff;
$denim: #0d74cf1a; // rgba(13, 116, 207, 0.1) Dropdown component active background color

// Allows use of variables when setting color of border

@mixin border-with-color($size, $color) {
  border: $size solid $color;
}
