@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

$starSize: 12px;

@mixin athlete-stars {
  * {
    background-size: $starSize $starSize;
    display: block;
    height: $starSize;
    width: $starSize;
  }
}

@mixin center-title {
  text-align: center;
}

@mixin data-row {
  @include widget-row;
  @include font-primary-body-B5;
  border-top: 1px solid $sand-30;
  min-height: 55px;

  &:first-child {
    border-top: none;
  }
}

@mixin dark-mode-data-row {
  border-color: $stone;
}

@mixin ordinality {
  @include font-rivals-numbers-r2;
  color: $abyss-90;
  display: flex;
  justify-content: center;
}

@mixin title-row {
  @include font-primary-bold-SB7;
  @include widget-row;
  text-transform: uppercase;
}

@mixin widget-row {
  align-items: center;
  column-gap: $spacing-12;
  display: grid;
  padding-bottom: $spacing-6;
  padding-top: $spacing-4;
}

@mixin widget-header-title-select {
  // The !important is needed to correctly position the Ant Design Select on the Team Rankings and Scores widgets
  position: absolute !important; //stylelint-disable-line declaration-no-important
  right: 0;
  top: 18px;
  width: 100%;

  :global {
    .ant-select-item {
      padding: 0;
    }

    .ant-select-selector > .ant-select-selection-item {
      display: none;
    }
  }
}

@mixin widget-header-option-item {
  align-items: center;
  column-gap: $spacing-4;
  display: grid;
  grid-template-columns: 19px 1fr;

  svg {
    margin: 0 auto;
  }
}

@mixin widget-header-option-name {
  @include font-primary-medium-S5;
  color: $abyss-90;
  text-transform: capitalize;
}
