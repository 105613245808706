$yahoo-sans: var(--yahoo-sans), "Helvetica Neue", arial, sans-serif;
$gt-america: var(--gt-america), -apple-system, BlinkMacSystemFont, Helvetica,
  Arial, sans-serif;
$gt-america-header: var(--gt-america-extended), -apple-system,
  BlinkMacSystemFont, Helvetica, "Arial Black", Arial, sans-serif;
$rivals-numbers-logo: var(--rivals-numbers-logo);
$rivals-numbers-ranking: var(--rivals-numbers-ranking);

$fontSize0: 73px;
$fontSize1: 41px;
$fontSize2: 25px;
$fontSize3: 21px;
$fontSize4: 17px;
$fontSize5: 13px;
$fontSize6: 11px;
$fontSize7: 9px;
$fontSize8: 7px;

// Rivals numbering font mixins

@mixin rivals-default {
  font-family: $gt-america;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin rivals-extended {
  font-family: $gt-america-header;
  font-stretch: expanded;
  font-style: normal;
  letter-spacing: normal;
}

// Standard Normal mixins

@mixin font-primary-body-B0 {
  @include rivals-default;
  font-size: $fontSize0;
  font-weight: 400;
  line-height: 1.1;
}

@mixin font-primary-body-B1 {
  @include rivals-default;
  font-size: $fontSize1;
  font-weight: 400;
  line-height: 1.17;
}

@mixin font-primary-body-B2 {
  @include rivals-default;
  font-size: $fontSize2;
  font-weight: 400;
  line-height: 1.17;
}

@mixin font-primary-body-B3 {
  @include rivals-default;
  font-size: $fontSize3;
  font-weight: 400;
  line-height: 1.28;
}

@mixin font-primary-body-B4 {
  @include rivals-default;
  font-size: $fontSize4;
  font-weight: 400;
  line-height: 28px;
}

@mixin font-primary-body-B5 {
  @include rivals-default;
  font-size: $fontSize5;
  font-weight: 400;
  line-height: 1.45;
}

@mixin font-primary-body-B6 {
  @include rivals-default;
  font-size: $fontSize6;
  font-weight: 400;
  line-height: 1.33;
}

@mixin font-primary-body-B7 {
  @include rivals-default;
  font-size: $fontSize7;
  font-weight: 400;
  line-height: 1.33;
}

@mixin font-primary-body-B8 {
  @include rivals-default;
  font-size: $fontSize8;
  font-weight: 400;
  line-height: 1.33;
}

// Standard Medium mixins

@mixin font-primary-medium-S0 {
  @include rivals-default;
  font-size: $fontSize0;
  font-weight: 500;
  line-height: 1.1;
}

@mixin font-primary-medium-S1 {
  @include rivals-default;
  font-size: $fontSize1;
  font-weight: 500;
  line-height: 1.17;
}

@mixin font-primary-medium-S2 {
  @include rivals-default;
  font-size: $fontSize2;
  font-weight: 500;
  line-height: 1.17;
}

@mixin font-primary-medium-S3 {
  @include rivals-default;
  font-size: $fontSize3;
  font-weight: 500;
  line-height: 1.28;
}

@mixin font-primary-medium-S4 {
  @include rivals-default;
  font-size: $fontSize4;
  font-weight: 500;
  line-height: 28px;
}

@mixin font-primary-medium-S5 {
  @include rivals-default;
  font-size: $fontSize5;
  font-weight: 500;
  line-height: 1.45;
}

@mixin font-primary-medium-S6 {
  @include rivals-default;
  font-size: $fontSize6;
  font-weight: 500;
  line-height: 1.33;
}

@mixin font-primary-medium-S7 {
  @include rivals-default;
  font-size: $fontSize7;
  font-weight: 500;
  line-height: 1.33;
}

@mixin font-primary-medium-S8 {
  @include rivals-default;
  font-size: $fontSize8;
  font-weight: 500;
  line-height: 1.33;
}

// Standard Bold mixins

@mixin font-primary-bold-SB0 {
  @include rivals-default;
  font-size: $fontSize0;
  font-weight: 700;
  line-height: 1.1;
}

@mixin font-primary-bold-SB1 {
  @include rivals-default;
  font-size: $fontSize1;
  font-weight: 700;
  line-height: 1.17;
}

@mixin font-primary-bold-SB2 {
  @include rivals-default;
  font-size: $fontSize2;
  font-weight: 700;
  line-height: 1.17;
}

@mixin font-primary-bold-SB3 {
  @include rivals-default;
  font-size: $fontSize3;
  font-weight: 700;
  line-height: 1.28;
}

@mixin font-primary-bold-SB4 {
  @include rivals-default;
  font-size: $fontSize4;
  font-weight: 700;
  line-height: 28px;
}

@mixin font-primary-bold-SB5 {
  @include rivals-default;
  font-size: $fontSize5;
  font-weight: 700;
  line-height: 1.45;
}

@mixin font-primary-bold-SB6 {
  @include rivals-default;
  font-size: $fontSize6;
  font-weight: 700;
  line-height: 1.33;
}

@mixin font-primary-bold-SB7 {
  @include rivals-default;
  font-size: $fontSize7;
  font-weight: 700;
  line-height: 1.33;
}

@mixin font-primary-bold-SB8 {
  @include rivals-default;
  font-size: $fontSize8;
  font-weight: 700;
  line-height: 1.33;
}

// Extended Bold mixins

@mixin font-extended-headline-H0 {
  @include rivals-extended;
  font-size: $fontSize0;
  font-weight: 700;
  line-height: 1.1;
}

@mixin font-extended-headline-H1 {
  @include rivals-extended;
  font-size: $fontSize1;
  font-weight: 700;
  line-height: 1.17;
}

@mixin font-extended-headline-H2 {
  @include rivals-extended;
  font-size: $fontSize2;
  font-weight: 700;
  line-height: 1.17;
}

@mixin font-extended-headline-H3 {
  @include rivals-extended;
  font-size: $fontSize3;
  font-weight: 700;
  line-height: 1.28;
}

@mixin font-extended-headline-H4 {
  @include rivals-extended;
  font-size: $fontSize4;
  font-weight: 700;
  line-height: 28px;
}

@mixin font-extended-headline-H5 {
  @include rivals-extended;
  font-size: $fontSize5;
  font-weight: 700;
  line-height: 1.45;
}

@mixin font-extended-headline-H7 {
  @include rivals-extended;
  font-size: $fontSize7;
  font-weight: 700;
  line-height: 10.8px;
}

// Extended Superbold Mixins

@mixin font-extended-extrabold-A0 {
  @include rivals-extended;
  font-size: $fontSize0;
  font-weight: 900;
  line-height: 1.1;
}

@mixin font-extended-extrabold-A1 {
  @include rivals-extended;
  font-size: $fontSize1;
  font-weight: 900;
  line-height: 1.17;
}

@mixin font-extended-extrabold-A2 {
  @include rivals-extended;
  font-size: $fontSize2;
  font-weight: 900;
  line-height: 1.17;
}

@mixin font-extended-extrabold-A3 {
  @include rivals-extended;
  font-size: $fontSize3;
  font-weight: 900;
  line-height: 1.28;
}

@mixin font-extended-extrabold-A4 {
  @include rivals-extended;
  font-size: $fontSize4;
  font-weight: 900;
  line-height: 28px;
}

@mixin font-extended-extrabold-A5 {
  @include rivals-extended;
  font-size: $fontSize5;
  font-weight: 900;
  line-height: 1.45;
}

// Rivals numbering font mixins

@mixin rivals-numbers-logo {
  font-family: $rivals-numbers-logo;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin rivals-numbers-ranking {
  font-family: $rivals-numbers-ranking;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin font-rivals-numbers-l0 {
  @include rivals-numbers-logo;
  font-size: $fontSize0;
  font-weight: normal;
  line-height: 1.1;
}

@mixin font-rivals-numbers-l1 {
  @include rivals-numbers-logo;
  font-size: $fontSize1;
  font-weight: normal;
  line-height: 1.17;
}

@mixin font-rivals-numbers-l2 {
  @include rivals-numbers-logo;
  font-size: $fontSize2;
  font-weight: normal;
  line-height: 1.17;
}

@mixin font-rivals-numbers-l3 {
  @include rivals-numbers-logo;
  font-size: $fontSize3;
  font-weight: normal;
  line-height: 1.28;
}

@mixin font-rivals-numbers-l4 {
  @include rivals-numbers-logo;
  font-size: $fontSize4;
  font-weight: normal;
  line-height: 28px;
}

@mixin font-rivals-numbers-l5 {
  @include rivals-numbers-logo;
  font-size: $fontSize5;
  font-weight: normal;
  line-height: 1.45;
}

@mixin font-rivals-numbers-l6 {
  @include rivals-numbers-logo;
  font-size: $fontSize6;
  font-weight: 500px;
  line-height: 1.33;
}

@mixin font-rivals-numbers-l7 {
  @include rivals-numbers-logo;
  font-size: $fontSize7;
  font-weight: 500px;
  line-height: 1.33;
}

@mixin font-rivals-numbers-l8 {
  @include rivals-numbers-logo;
  font-size: $fontSize8;
  font-weight: 500px;
  line-height: 1.33;
}

@mixin font-rivals-numbers-r0 {
  @include rivals-numbers-ranking;
  font-size: $fontSize0;
  font-weight: normal;
  line-height: 1.1;
}

@mixin font-rivals-numbers-r1 {
  @include rivals-numbers-ranking;
  font-size: $fontSize1;
  font-weight: normal;
  line-height: 1.17;
}

@mixin font-rivals-numbers-r2 {
  @include rivals-numbers-ranking;
  font-size: $fontSize2;
  font-weight: normal;
  line-height: 1.17;
}

// Legacy Yahoo Sans font mixins

@mixin yahoo-default {
  font-family: $yahoo-sans;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

@mixin font-yahoo-h0 {
  @include yahoo-default;
  font-size: 73px;
  font-weight: 800;
  line-height: 1.1;
}

@mixin font-yahoo-h1 {
  @include yahoo-default;
  font-size: 41px;
  font-weight: 800;
  line-height: 1.17;
}

@mixin font-yahoo-h1-secondary {
  @include yahoo-default;
  font-size: 41px;
  font-weight: bold;
  line-height: 1.17;
}

@mixin font-yahoo-h2 {
  @include yahoo-default;
  font-size: 25px;
  font-weight: 800;
  line-height: 1.28;
}

@mixin font-yahoo-h25 {
  @include yahoo-default;
  font-size: 21px;
  font-weight: 800;
  line-height: 28px;
}

@mixin font-yahoo-h25-secondary {
  @include yahoo-default;
  font-size: 21px;
  font-weight: bold;
  line-height: 28px;
}

@mixin font-yahoo-h3 {
  @include yahoo-default;
  font-size: 17px;
  font-weight: 800;
  line-height: 1.41;
}

@mixin font-yahoo-h3-secondary {
  @include yahoo-default;
  font-size: 17px;
  font-weight: bold;
  line-height: 1.41;
}

@mixin font-yahoo-h4 {
  @include yahoo-default;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.54;
}

@mixin font-yahoo-h5 {
  @include yahoo-default;
  font-size: 11px;
  font-weight: bold;
  line-height: 1.45;
}

@mixin font-yahoo-h6 {
  @include yahoo-default;
  font-size: 9px;
  font-weight: bold;
  line-height: 1.33;
}

@mixin font-yahoo-h7 {
  @include yahoo-default;
  font-size: 7px;
  font-weight: bold;
  line-height: 1.33;
}

@mixin font-yahoo-b0 {
  @include yahoo-default;
  font-size: 73px;
  font-weight: normal;
  line-height: 1.1;
}

@mixin font-yahoo-b1 {
  @include yahoo-default;
  font-size: 41px;
  font-weight: normal;
  line-height: 1.17;
}

@mixin font-yahoo-b2 {
  @include yahoo-default;
  font-size: 25px;
  font-weight: normal;
  line-height: 1.81;
}

@mixin font-yahoo-b25 {
  @include yahoo-default;
  font-size: 21px;
  font-weight: normal;
  line-height: 28px;
}

@mixin font-yahoo-b3 {
  @include yahoo-default;
  font-size: 17px;
  font-weight: normal;
  line-height: 1.41;
}

@mixin font-yahoo-b4 {
  @include yahoo-default;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.54;
}

@mixin font-yahoo-b5 {
  @include yahoo-default;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.45;
}

@mixin font-yahoo-b6 {
  @include yahoo-default;
  font-size: 9px;
  font-weight: 500;
  line-height: 1.33;
}

@mixin font-yahoo-b7 {
  @include yahoo-default;
  font-size: 7px;
  font-weight: 500;
  line-height: 1.33;
}
