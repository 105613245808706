@import "~rivals/styles/colors.scss";
@import "~rivals/styles/mixins.scss";
@import "~rivals/styles/variables.scss";
@import "~rivals/styles/typography.scss";

$titleHeight: 36px;
$actionIconSize: 19px;

.mainTitle {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.titleIcon {
  height: $titleHeight;
  margin-right: $spacing-4;
  width: $titleHeight;
}

.actionIcon {
  height: $actionIconSize;
  width: $actionIconSize;
}

.actionIconAnchoredTopRight {
  flex: 1;
  margin-bottom: auto;
  text-align: right;
}

.widgetTitle {
  padding-right: $spacing-4;
}

.eyebrowText {
  text-transform: uppercase;
}

.eyebrowText,
.secondaryTitleText {
  @include font-primary-bold-SB7;
}

.secondaryTitleText {
  margin-top: -$spacing-4;
}

.headerYear {
  @include font-rivals-numbers-l4;
  margin-right: $spacing-4;
}

.mainTitleText {
  @include font-extended-headline-H4;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: $spacing-12;
  text-transform: uppercase;
}

.noWrap {
  display: block;
  text-wrap: nowrap;
}

.titleLogo {
  height: $titleHeight;
  margin-bottom: $spacing-4;
  width: auto;
}
