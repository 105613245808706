@import "~rivals/styles/breakpoints";
@import "~rivals/styles/colors";
@import "~rivals/styles/typography";
@import "~rivals/styles/variables";

@mixin cursor-pointer {
  cursor: pointer;
}

// Primary + Secondary Buttons

@mixin button-container {
  align-items: center;
  display: flex;
  justify-content: center;
  justify-self: center;
  @include sizes-ending-with($screen-lg-mobile-max) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }
}

@mixin button-primary {
  margin-left: $spacing-12;
  @include sizes-ending-with($screen-lg-mobile-max) {
    margin-left: 0;
  }
}

@mixin button-secondary {
  margin-bottom: 0;
  margin-right: $spacing-12;
  @include sizes-ending-with($screen-lg-mobile-max) {
    margin: $spacing-12 auto 0;
    order: 2;
  }
}

// Standard wrapper styles

@mixin wrapper {
  margin: 0 auto;
  width: 100%;
  @include sizes-ending-with($screen-tablet-max) {
    max-width: 100%;
  }
  @include sizes-starting-with($screen-sm-desktop-min) {
    max-width: $container-width-desktop;
  }
  @include sizes-starting-with($screen-lg-desktop-min) {
    max-width: $container-width-desktop-XL;
  }
}

@mixin athlete-page-wrapper {
  margin: 0 auto;
  width: 100%;
  @include sizes-ending-with($screen-sm-desktop-max) {
    max-width: 100%;
  }
  @include sizes-starting-with($screen-lg-desktop-min) {
    max-width: $container-width-desktop-XL;
  }
}

@mixin header-wrapper {
  margin: 0 auto;
  width: auto;
  @include sizes-ending-with($screen-sm-mobile-max) {
    margin: 0 $spacing-12;
    max-width: $screen-mobile-min;
  }
  @include sizes-between($screen-mobile-min, $screen-tablet-max) {
    margin: 0 $spacing-24;
    max-width: $screen-tablet-max;
  }
  @include sizes-between($screen-sm-desktop-min, $new-article-md-desktop-min) {
    max-width: $screen-sm-desktop-min - 10;
  }
  @include sizes-between(
    $new-article-md-desktop-min,
    $new-article-md-desktop-max
  ) {
    max-width: $new-article-md-desktop-min - 10;
  }
  @include sizes-between($new-article-md-desktop-max, $screen-lg-desktop-min) {
    max-width: $new-article-md-desktop-max - 10;
  }
  @include sizes-starting-with($screen-lg-desktop-min) {
    max-width: $container-width-desktop-XL;
  }
}

@mixin article-block-wrapper {
  margin-bottom: $spacing-12;
}

@mixin registration-wrapper {
  @include profile-page-wrapper;
  margin: 0 auto;
  width: 100%;
  @include sizes-starting-with($screen-tablet-min) {
    max-width: $registration-container-width-max;
  }
}

// Antdesign overrides (Nav)

@mixin nav-menu-alignment {
  align-items: center;
  display: flex;
}

@mixin nav-menu-item-padding {
  padding: 0 16px;
}

@mixin nav-link-font {
  @include font-primary-body-B5;
  color: $black;
  text-decoration: none;
}

@mixin nav-link {
  @include nav-link-font;
  @media screen and (min-width: $screen-sm-desktop-min) {
    @include nav-menu-item-padding;
    height: 100%;
  }
}

@mixin inline-menu-hover {
  @include nav-link;
  background-color: transparent;
}

@mixin nav-submenu-item-padding {
  padding-left: $spacing-4;
  padding-right: $spacing-4;
}

// Shared wrapper styles

@mixin label-input-button-page-wrapper {
  margin: 0 auto;
  max-width: 351px;
  padding: 12px;
  @include sizes-starting-with($screen-tablet-min) {
    padding: 24px;
  }
}

@mixin list-filters-wrapper {
  align-items: center;
  background: $white;
  border-bottom: $border-85;
  display: flex;
  height: 76px;
  padding: 0 $spacing-24;
  @include sizes-ending-with($screen-lg-mobile-max) {
    padding: 0 $spacing-12;
  }
}

@mixin label-input-button-page {
  h3 {
    @include font-yahoo-h3;
  }

  .forgotInputGroup {
    padding-bottom: 24px;
  }
}

@mixin page-divider {
  background-color: $gray85;
  height: 1px;
  margin: $spacing-24 0;
  width: 100%;
}

@mixin profile-img {
  border-radius: calc($profile-img-length / 2);
  height: $profile-img-length;
  object-fit: cover;
  width: $profile-img-length;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: $profile-img-mobile-length;
    margin: $spacing-12 0;
    width: $profile-img-mobile-length;
  }
}

@mixin profile-info-block {
  padding: $spacing-24 20px 30px;
  position: relative;
  @include athlete-page-wrapper;
  @include sizes-ending-with($screen-lg-mobile-max) {
    padding: $spacing-12 $spacing-12 30px;
  }
}

@mixin profile-page-wrapper {
  @include sizes-starting-with($screen-tablet-min) {
    background-color: $gray95;
    max-width: 100%;
    padding: $spacing-24 $spacing-12;
  }
}

@mixin profile-section-header-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-12;
}

@mixin profile-section-header {
  @include font-yahoo-h2;
  color: $black;
  font-size: 21px;
  margin-bottom: 0;
  text-transform: uppercase;
}

@mixin section-container {
  @include sizes-ending-with($screen-lg-mobile-max) {
    border-radius: 0;
  }
  background-color: $white;
  color: $black;
  display: block;
  padding: 24px;
  @include font-yahoo-b4;
}

@mixin gradient-background {
  &::before {
    background-image: linear-gradient(74deg, $card-gradient-light 7%, $black);
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @include sizes-ending-with($screen-tablet-max) {
      background-image: linear-gradient(53deg, $card-gradient-light 7%, $black);
    }
    @include sizes-ending-with($screen-lg-mobile-max) {
      background-image: linear-gradient(30deg, $card-gradient-light 7%, $black);
    }
    @include sizes-ending-with($screen-mobile-max) {
      background-image: linear-gradient(21deg, $card-gradient-light 7%, $black);
    }
  }

  &::after {
    background-image: linear-gradient(
      74deg,
      $card-gradient-light,
      $card-gradient-dark 52%,
      $black
    );
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.85;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    @include sizes-ending-with($screen-tablet-max) {
      background-image: linear-gradient(
        53deg,
        $card-gradient-light,
        $card-gradient-dark 52%,
        $black
      );
    }
    @include sizes-ending-with($screen-lg-mobile-max) {
      background-image: linear-gradient(
        30deg,
        $card-gradient-light,
        $card-gradient-dark 52%,
        $black
      );
    }
    @include sizes-ending-with($screen-mobile-max) {
      background-image: linear-gradient(
        21deg,
        $card-gradient-light,
        $card-gradient-dark 52%,
        $black
      );
    }
  }
}

@mixin athlete-profile-anchor {
  position: absolute;
  top: $scroll-anchor-desktop;
  @include sizes-ending-with($screen-lg-mobile-max) {
    top: $scroll-anchor-mobile;
  }
}

@mixin adjust-table-columns-widths($columns-widths: ()) {
  $i: 1;
  @each $value in $columns-widths {
    thead tr th:nth-child(#{$i}),
    tbody tr td:nth-child(#{$i}) {
      width: $value;
    }
    $i: $i + 1;
  }
}

@mixin page-title-italic {
  @include font-yahoo-h0;
  color: $rivals-primary;
  font-style: italic;
  text-transform: uppercase;
  @include sizes-ending-with($screen-tablet-max) {
    @include font-yahoo-h1;
    font-style: italic;
  }
}

// AthleteProfileBar styles

@mixin athlete-profile-bar-content {
  // TODO: remove after prospect page is removed https://griosf.atlassian.net/browse/RVLS-5903
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  margin: 0 auto;
  width: $screen-sm-desktop-min;
  @include sizes-ending-with($screen-tablet-max) {
    width: $screen-tablet-min;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    width: $screen-lg-mobile-min;
  }
  @include sizes-ending-with($screen-mobile-max) {
    width: $screen-mobile-min;
  }
  @include sizes-ending-with($screen-sm-mobile-max) {
    width: 100vw;
  }
}

@mixin drop-down-profile-bar {
  background-color: $white;
  height: 80px;
  left: 0px;
  position: fixed;
  top: -80px;
  width: 100%;
  z-index: 99;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: 54px;
  }
}

@mixin profile-bar-divider-line($color) {
  border: solid 0.5px $color;
  height: 60px;
  width: 0.5px;
  @include sizes-ending-with($screen-lg-mobile-max) {
    height: 40px;
  }
}

@mixin content-fonts-size {
  @include font-yahoo-h4;
  @include sizes-ending-with($screen-tablet-max) {
    @include font-yahoo-h5;
  }
  @include sizes-ending-with($screen-lg-mobile-max) {
    @include font-yahoo-h6;
  }
}

// Career highlights timeline mixins start

@mixin draw-timeline {
  background: $career-highlight-gray;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 100%;
}

@mixin timeline-deriving-branch(
  $bottom: 50%,
  $top: 50%,
  $height: $career-highlight-branch-height
) {
  border-left: 1px solid $career-highlight-gray;
  bottom: $bottom;
  content: "";
  height: $height;
  left: 50%;
  position: absolute;
  top: $top;
  width: 50%;
}

@mixin multi-day-event-branch($xPosition, $yPosition) {
  border-#{$xPosition}: 1px solid $career-highlight-gray;
  height: $career-highlight-branch-height;
  position: absolute;
  width: 50%;
  #{$yPosition}: calc(50% + $career-highlight-branch-height);
  #{$xPosition}: 50%;
}

// Career highlights timeline mixins end

// Disables text selection highlighting on different browsers

@mixin unselectable-text {
  -webkit-touch-callout: none; // iOS Safari
  -webkit-user-drag: none; // disabled link drag
  -webkit-user-select: none; // Safari
  -khtml-user-select: none; // Konqueror HTML
  -moz-user-select: none; // Old versions of Firefox
  -ms-user-select: none; // Internet Explorer/Edge
  user-select: none; // Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox
}

// When scroll behavior is needed but scrollbars should be hidden, use the following mixin
// When scroll behavior is NOT needed, use `overflow: hidden;` to stop the scroll and hide the bars

@mixin hide-scrollbars {
  -ms-overflow-style: none; // Internet Explorer 10+
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Chrome and Safari
  }
}

@mixin page-header-college-logo {
  color: $white;
  height: 80px;
  width: 80px;
  @include font-yahoo-h1;
  @include sizes-ending-with($screen-tablet-max) {
    height: 40px;
    width: 40px;
    @include font-yahoo-h2;
  }
}

@mixin avoid-elements-break {
  // tell the browser to keep specific elements together, usual use is with multi-column layout
  // reference: https://css-tricks.com/almanac/properties/b/break-inside/
  break-inside: avoid; // IE 10+
  -webkit-column-break-inside: avoid; // Chrome, Safari, Opera
  page-break-inside: avoid; // Firefox
}

/* TODO: Review these styles when https://griosf.atlassian.net/browse/RVLS-8341 is picked up */

@mixin disabled-state-styles($color: null, $bgColor: null) {
  @include unselectable-text;
  cursor: not-allowed;
  opacity: 0.6;
  pointer-events: none;
  @if $color {
    color: $color;
  }
  @if $bgColor {
    background-color: $bgColor;
  }
}

@mixin light-mode {
  background-color: $bone;
  color: $abyss;
}

@mixin dark-mode {
  background-color: rgba($abyss, 0.7);
  color: $bone;
}

@mixin fixed-banner-position {
  bottom: $spacing-12;
  margin: 0 $spacing-24;
  position: fixed;
  @include sizes-ending-with($screen-lg-mobile-max) {
    margin: 0 $spacing-8;
  }
}
