@use "~rivals/styles/breakpoints" as *;

@use "~rivals/styles/variables" as *;
@import "~rivals/styles/colors.scss";
@import "~rivals/styles/mixins.scss";

/**
* The following *WidgetWrapper styles are applied to the widgets in the rails on the homepage and article page
**/

.standardWidgetWrapper,
.scoresWidgetWrapper,
.topTargetsWrapper {
  max-width: $homepage-main-content-width;
  width: 100%;
}

.standardWidgetWrapper .container {
  min-height: 400px;
  @include sizes-ending-with($screen-tablet-max) {
    min-height: 300px;
  }
}

.scoresWidgetWrapper .container {
  min-height: 485px;
  @include sizes-ending-with($screen-tablet-max) {
    min-height: 300px;
  }
}

.topTargetsWrapper .container {
  min-height: 250px;
  @include sizes-ending-with($screen-tablet-max) {
    min-height: 200px;
  }
}

.container {
  @include light-mode;
  border: 1px solid $sand-30;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.titleContainer {
  padding: $spacing-16 0 $spacing-12;
  width: 100%;
}

.sponsorTitleContainer {
  padding-bottom: 4px;
  padding-top: 0;
}

.body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
  width: 100%;
}

.buttonContainer {
  margin-top: auto;
  padding: $spacing-12 0 $spacing-24 0;
}

.buttonContainer button > span {
  transform: translateY(1px);
}

.buttonFullWidth {
  padding-bottom: 0;
}

.darkMode {
  @include dark-mode;
  border: $clay-border;
}

.noBorder {
  border: none;
}
