@import "~rivals/components/shared/Widgets/mixins";

.centerTitle {
  @include center-title;
}

.darkMode {
  color: $bone;
}

.dataRow {
  @include data-row;

  &.darkModeRow {
    @include dark-mode-data-row;
  }
}

.ordinality {
  @include ordinality;

  &.darkMode {
    color: $bone;
  }
}

.linkOverride {
  color: $abyss;

  &:hover {
    color: $abyss-70;
  }

  &.darkMode {
    color: $bone;

    &:hover {
      color: $bone-30;
    }
  }
}

.smallButton {
  height: 36px;
}

.titleRow {
  @include title-row;
}

a > .externalIcon {
  svg {
    path {
      fill: $abyss;
    }
  }

  &:hover {
    svg {
      path {
        fill: $abyss-70;
      }
    }
  }
}

a > .externalIcon.darkMode {
  svg {
    path {
      fill: $bone;
    }
  }

  &:hover {
    svg {
      path {
        fill: $bone-30;
      }
    }
  }
}
